import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

// Components
import Layout from "../components/layout"
import Hero from "../components/hero"
import { Seo } from "../components/seo"

// Loadable Components
const TextBlock = loadable(() => import("../components/textBlock"))
const Pricing = loadable(() => import("../components/pricing"))
const Highlight = loadable(() => import("../components/highlight"))
const Interlude = loadable(() => import("../components/interlude"))

const Info = ({ data }) => {
  const info = data.contentfulPage

  return (
    <Layout>
      <Hero
        type={info.pageType}
        title={info.pageTitle.split("\\n")}
        image={info.heroImage}
      />

      {info.pageContent.map((content, i) => {
        if (content.internal.type === "ContentfulTextBlock") {
          return <TextBlock key={i} title={content.title} text={content.text} />
        } else if (content.internal.type === "ContentfulPricing") {
          return (
            <Pricing
              key={i}
              title={content.title}
              prices={content.prices}
              seasons={content.seasons}
              additionalInfos={content.additionalInfos.additionalInfos}
            />
          )
        } else if (content.internal.type === "ContentfulHighlight") {
          return (
            <Highlight
              key={i}
              caption={content.content}
              title={content.title}
              cta={content.cta}
              image={content.image}
              location={content.location}
              link={content.link}
              isEven={content.isEven}
              isReverse={content.isReverse}
            />
          )
        } else if (content.internal.type === "ContentfulInterlude") {
          return (
            <Interlude
              key={i}
              // description={content.description.description}
              images={content.images}
              layout={content.layout}
            />
          )
        } else {
          return null
        }
      })}
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(pageName: { eq: "Info" }) {
      pageType
      pageTitle
      heroImage {
        title
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 50)
      }
      pageContent {
        ... on ContentfulTextBlock {
          internal {
            type
          }
          title
          text {
            raw
          }
        }

        ... on ContentfulPricing {
          internal {
            type
          }
          title
          seasons {
            highSeason
            lowSeason
          }
          prices {
            title
            discount
            double {
              highSeason
              lowSeason
            }
            single {
              highSeason
              lowSeason
            }
          }
          additionalInfos {
            additionalInfos
          }
        }

        ... on ContentfulHighlight {
          internal {
            type
          }
          title
          cta
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                gatsbyImageData(height: 300)
              }
            }
          }
          image {
            description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          location {
            lat
            lon
          }
          link
          isEven
          isReverse
        }

        ... on ContentfulInterlude {
          internal {
            type
          }
          description {
            description
          }
          images {
            description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          layout
        }
      }
    }
  }
`

export default Info

export const Head = () => <Seo title="B&B S. Stae | Info" />
